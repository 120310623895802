@import '../../../styles/variables';
@import '../../../styles/global';

.page {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
}

.content {
  padding: 30px 50px;
  width: 800px;
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
}

