@mixin center-flex($display) {
  display: $display;
  align-items: center;
  justify-content: center;
}

@mixin center-flex-x($display) {
  display: $display;
  justify-content: center;
}

@mixin center-flex-y($display) {
  display: $display;
  align-items: center;
}

@mixin box-shadow() {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);
}

@mixin border-box-sizing() {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@mixin text-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin no-select() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin scrollbars() {
  $color-scroll-back: $color-grey3;
  $color-scroll-front: $color-main-dark;

  // For Google Chrome
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-scroll-front;
  }

  &::-webkit-scrollbar-track {
    background: $color-scroll-back;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $color-scroll-front;
    scrollbar-track-color: $color-scroll-back;
  }

  // For Firefox
  & {
    scrollbar-color: $color-scroll-front $color-scroll-back;
    scrollbar-width: thin;
  }
}
