@import "../../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-size: $font-size-huge;
}

.link {
  display: flex;
  cursor: pointer;
  color: $color-stadim-green;
  align-items: center;

  .icon {
    fill: $color-stadim-green;
    width: 20px;
    height: 20px;
    margin-left: -5px;
  }
}
