.container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  35% {
    opacity: 1;
    transform: translateX(-10%);
  }
  55% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toast {
  animation-name: slideInFromRight;
  animation-duration: 1s;
}
