@import "../../../styles/variables";
@import "../../../styles/global";



.toggleSwitch {
  width: 75px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
  &Checkbox {
    display: none;
  }
  &Label {
    position: relative;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &Inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "Yes";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $color-stadim-blue;
      color: #fff;
    }
  }
  &Disabled {
    background-color: $color-stadim-disabled;
    cursor: not-allowed;
    &:before {
      background-color: $color-stadim-disabled;
      cursor: not-allowed;
    }
  }
  &Inner:after {
    content: "No";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $color-stadim-disabled;
    color: #fff;
    text-align: right;
  }
  &Switch {
    display: block;
    width: 24px;
    height: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 5px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &Checkbox:checked + &Label {
    .toggleSwitchInner {
      margin-left: 0;
    }
    .toggleSwitchSwitch {
      right: 0px;
    }
  }
}
