@import "../../../styles/variables";
@import "../../../styles/global";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  @include no-select();
  cursor: pointer;
  width: 100%;
  background-color: $color-light;
  border-radius: $border-radius-standard;
  padding-left: 10px;
  margin-bottom: 10px;
  height: $line-height;
  display: flex;
  align-items: center;
  border: $border-standard;
  font-size: $font-size-big;

  &:hover {
    background-color: darken($color-light, 5%);
  }

  &.selected {
    background-color: $color-action;
    color: $color-white;
    border: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.checkBoxPanel {
  display: flex;
  align-items: center;
  width: 40px;

  .checkIcon {
    fill: $color-white;
    width: 25px;
    height: 25px;
  }
}

.categoryBox {
    display: flex;

  .categoryTitle {
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
  }

  .optionsList {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}


