@import '../../../styles/variables';
@import '../../../styles/global';

.page {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
}

.content {
  padding: 30px;
}

.panel {
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 20px;
  background-color: white;
  min-width: 800px;
}

.formInputFields {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.formGroup {
  border: 1px solid $color-medium;
  padding: 30px;
  border-radius: $border-radius-standard;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .formGroupLabel {
    font-size: $font-size-small;
    position: absolute;
    top: -10px;
    left: 20px;
    z-index: 100;
    background-color: white;
    padding: 0 10px;
  }
}

.formField {
  width: 100%;
}

.input, .labelContainer, .dropdownInput, .inputBox {
  width: calc(100%);
}

.inputContainer {
  display: flex;
  align-items: center;
}

.dropdownInput {
  margin-top: 5px;
}

.input {
  height: $line-height;
  background-color: $color-light;

  &.disabled {
    background-color: $color-medium;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.unit {
  margin-top: 5px;
  margin-left: -40px;
  height: $line-height;
  display: flex;
  align-items: center;
}

.input:focus {
  border-radius: $border-radius-standard;
  border: 1px solid $color-action;
}

.inputInvalid, .inputInvalid:focus {
  border: 1px solid $color-invalid !important;
}

.buttons {
  margin-top: 30px;
}

.floatRight {
  float: right;
}

.invalid {
  display: inline-flex;
  padding-left: 10px;
  vertical-align: middle;
  text-align: center;
  height: $line-height;

  img {
    width: 20px;
    fill: $color-invalid;
  }
}

.loadingIcon {
  fill: $color-white;
  width: 20px;
  height: 20px;
}

.checkboxCentered {
  padding-bottom: 20px;
  display: flex;
  cursor: pointer;
  line-height: 26px;
  width: 100%;

  .checkbox {
    margin-top: 7px;
    margin-right: 10px;
  }

  .checkboxLabel {
    max-width: 500px;
  }
}

.inputBox {
  display: flex;
}

.numberInput {
  width: 100%;

  &.withUnit {
    padding-right: 50px;
  }
}

.footnote {
  padding-top: 8px;
  font-size: $font-size-small;
  font-family: TTNormsItalic;
}

.errorsPanel {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: lighten($color-invalid, 50%);
  border: 1px solid $color-invalid;
  border-radius: $border-radius-standard;
}

.warning {

  color: $color-invalid;
  &Text {
    font-size: $font-size-bigger;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  &Info {
    font-size: $font-size-small;
    font-style: italic;
    font-weight: 300;
  }

}