@import '../../../styles/variables';
@import '../../../styles/global';

.formField {
  width: 100%;
}

.input, .labelContainer, .dropdownInput, .inputBox {
  width: calc(100%);
}

.inputContainer {
  display: flex;
  align-items: center;
}

.dropdownInput {
  margin-top: 5px;
}


.textareaInput {
  padding: 5px 10px;
  width: 100%;
  background-color: $color-light;

  &.disabled {
    background-color: $color-medium;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.input {
  height: $line-height;
  background-color: $color-light;

  &.disabled {
    background-color: $color-medium;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.unit {
  margin-top: 5px;
  margin-left: -40px;
  height: $line-height;
  display: flex;
  align-items: center;
}

.input:focus {
  border-radius: $border-radius-standard;
  border: 1px solid $color-action;
}

.inputInvalid, .inputInvalid:focus {
  border: 1px solid $color-invalid !important;
}

.floatRight {
  float: right;
}

.invalid {
  display: inline-flex;
  padding-left: 10px;
  vertical-align: middle;
  text-align: center;
  height: $line-height;

  img {
    width: 20px;
    fill: $color-invalid;
  }
}


.inputBox {
  display: flex;
}

.numberInput {
  width: 100%;

  &.withUnit {
    padding-right: 50px;
  }
}

.footnote {
  padding-top: 8px;
  font-size: $font-size-small;
  font-family: TTNormsItalic;
}
