@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  @include center-flex-x(flex);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
}

.popupContainer {
  position: relative;
  top: 90px;
  background-color: $color-white;
  border: $border-standard;
  border-radius: 10px;
  padding: 20px;

  &Small {
    width: 500px;
    height: 200px;
  }

  &Medium {
    width: 600px;
    height: 300px;
  }

  &Large {
    width: 800px;
    height: 400px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  fill: $color-stadim-green;
  width: 15px;
  height: 15px;
  margin-left: -5px;
}

