@import "./variables";
@import "./fonts";
@import "./mixins";

//============================//
//======= BODY HTML ==========//
//============================//
html {
  min-height: 100%;
  font-variant-ligatures: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: $color-white;
  font-family: TTNormsRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-normal;
  min-height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-variant-ligatures: none;
}

//=========================================//
//========== TEXT INPUT STYLE ============//
//=========================================//

.input {
  margin-top: 5px;
  height: 35px;
  width: 100%;
  border-radius: $border-radius-standard;
  font-size: $font-size-normal;
  border: $border-standard;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.textareaInput {
  font-family: sans-serif;
  margin-top: 5px;
  border-radius: $border-radius-standard;
  font-size: $font-size-normal;
  border: $border-standard;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
}

//=========================================//
//============= BUTTON STYLES =============//
//=========================================//

.button {
  @include center-flex(flex);
  @include no-select();
  background-color: $color-action;
  color: $color-white;
  padding: 0 30px;
  height: $line-height;
  cursor: pointer;
  outline: none;
  border: $border-standard;
  border-radius: $border-radius-standard;
  font-size: $font-size-normal;
  min-width: 150px;

  &:hover {
    background-color: lighten($color-action, 10%);
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    background-color: $color-medium;

    &:hover {
      background-color: $color-medium;
    }
  }

  &.negative {
    background-color: $color-white;
    color: $color-action;
    border: 1px solid $color-action;

    &:hover {
      background-color: $color-action;
      color: $color-white;
    }
  }
}

.buttons {
  @include center-flex(flex);
  padding: 20px 0;

  .button {
    margin: 0 10px
  }

  &.alignLeft {
    justify-content: flex-start;

    .button {
      margin-left: 0;
      margin-right: 15px
    }
  }

  &.alignRight {
    justify-content: flex-end;

    .button {
      margin-right: 0;
      margin-left: 15px;
    }
  }
}
