@import "../../../styles/variables";
@import "../../../styles/global";
@import "../../../styles/mixins";

.pagingContainer {
  @include no-select();
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}

.pageNumberContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageNumber {
  min-width: 25px;
  padding: 0 5px;
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  justify-content: center;

  &:hover {
    color: $color-action;
  }
}

.placeholderNumber {
  width: 30px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.pageNumberActive {
  color: $color-action;
}

.changePage {
  cursor: pointer;

  &:hover {
    color: $color-action;
  }
}

.rightPanel {
  display: flex;
}

.itemsPerPage {
  padding-right: 10px;
}
