@import '../../../styles/variables';
@import '../../../styles/global';

.page {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
}

.content {
  padding: 30px 50px;
  max-width: 800px;
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
}

.panel {
  line-height: 24px;
  width: 100%;
  border: 1px solid $color-medium;
  border-radius: $border-radius-standard;

  .panelTitle {
    font-size: $font-size-bigger;
    font-family: TTNormsBold;
    padding: 10px 20px;
    border-bottom: 1px solid $color-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.field {
  display: flex;
  padding: 5px 20px;
  line-height: 40px;

  .label {
    min-width: 250px;
    display: flex;
  }

  &:nth-child(even) {
    background-color: $color-light;
  }
}
