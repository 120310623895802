@import "../../../styles/variables";
@import "../../../styles/global";

.page {
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: $color-light;
}

.container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-color: $color-light;
  border: none;
  padding: 30px;
  border-radius: 0;
  color: #999;

  .imgContainer{
    text-align: center;
    max-height: 400px;
    height: 100%;
    .img {
      height: 100%;
    }
  }
  .textContainer{
    color: #6c6c6c;
    text-align: center;

    p {
      padding: 10px;
    }
  }

}


