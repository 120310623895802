@import '../../../styles/variables';
@import '../../../styles/global';

.page {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
}

.content {
  padding: 30px 50px;
  height: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

table {
  width: 100%;
  border: $border-standard;
  border-radius: $border-radius-standard;
  display: inline-block;
  background-color: white;
  min-width: 800px;
  border-collapse: collapse;
  max-height: calc(100vh - #{$app-header-height} - 215px);
  overflow-y: auto;
}

.title {
  font-size: $font-size-huge;
}

thead {
  position: sticky;
  top: 0;
  border-top-left-radius: $border-radius-standard;
  border-top-right-radius: $border-radius-standard;
  background-color: $color-action;
  color: $color-white;
  font-family: TTNormsBold;
}

tr {
  height: 40px;
  width: 100%;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: $color-light;
  }

  td, th {
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    padding: 0 15px;

    &.extraLarge {
      min-width: 200px;
      max-width: 200px;
    }

    &.large {
      min-width: 145px;
      max-width: 145px;
    }

    &.medium {
      min-width: 120px;
      max-width: 120px;
    }

    &.small {
      min-width: 90px;
      max-width: 90px;
    }

    &.extraSmall {
      min-width: 55px;
      max-width: 55px;
    }
  }
}

.empty {
  display: table-cell;
  justify-content: center;
  padding: 30px;
  margin: auto;
}

.download {
  width: 25px;
  color: $color-action;

  &:hover {
    cursor: pointer;
  }
}

.loadingIcon {
  fill: $color-action;
  width: 20px;
  height: 20px;
  margin: auto;
}

.footnote {
  padding-top: 8px;
}

.contentTop {
  display: flex;
  justify-content: space-between;

  .tableControls {
    display: flex;
    width: 100%;
  }
}

.sorting {
  margin-left: 20px;
  margin-right: 10px;
  width: 250px;
}

.searchBar {
  display: flex;
  align-items: center;
  background-color: $color-light;
  border: 1px solid $color-medium;
  height: $line-height;
  border-radius: $border-radius-standard;
  min-width: 400px;
  width: 100%;
  flex: 1;

  .searchIconPanel {
    padding-left: 20px;

    .searchIcon {
      fill: darken($color-medium, 10%);
      width: 20px;
      height: 20px;
    }
  }

  input {
    margin-top: 0;
    background-color: #f5f5f5;
    border: none;
    padding-left: 10px;
    height: calc(#{$line-height} - 2px);

    &:focus {
      outline: none;
      border: none;
    }
  }
}

.buttons {
  padding: 0;
  display: flex;
  gap: 10px;

  .button {
    margin: 0;
  }

  .buttonSmall {
    min-width: $line-height;
    padding: 0;
  }
}

.row {
  &:hover {
    cursor: pointer;
  }
}

.icon {
  fill: $color-stadim-green-dark;
  height: 15px;
  vertical-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  alignment-baseline: center;

  &:hover {
    fill: $color-stadim-green_light;
  }
}

.iconContainer{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.eyeIcon {
  fill: $color-white;
  width: 20px;
  height: 20px;
  margin: auto;
}
