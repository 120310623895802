@import "../../../styles/variables";

.container {
  border-radius: 5px;
  min-width: 300px;
  margin-right: 10px;
  margin-top: 20px;

  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 0 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px;

  &:hover {
    cursor: pointer;
  }
}

.content {
  background-color: $color-light;
  border-bottom: $border-standard;
  border-left: $border-standard;
  border-right: $border-standard;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-wrap: break-word;
  padding: 20px;
  text-align: center;
}

.header {
  padding: 10px;
  text-align: center;
  color: $color-white;
  font-weight: bold;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.error {
  background-color: $color-invalid;
}

.warning {
  color: $color-black;
  background-color: rgb(252, 252, 96);
}

.success {
  background-color: $color-positive;
}