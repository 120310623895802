@font-face {
  font-family: TTNormsRegular;
  src: url(../assets/fonts/TTNorms-Regular.otf);
}

@font-face {
  font-family: TTNormsLight;
  src: url(../assets/fonts/TTNorms-Light.otf);
}

@font-face {
  font-family: TTNormsBold;
  src: url(../assets/fonts/TTNorms-Bold.otf);
}

@font-face {
  font-family: TTNormsItalic;
  src: url(../assets/fonts/TTNorms-Italic.otf);
}
