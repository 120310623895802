@import "../../../styles/variables";
@import "../../../styles/global";

.dropdownPanel {
  position: relative;
  display: inline-block;
  width: 100%;
}

.selectionPanel {
  background-color: $color-light;
  height: $line-height;
  width: 100%;
  border-radius: $border-radius-standard;
  font-size: $font-size-normal;
  border: $border-standard;
  outline: none;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border: $border-standard;
  //min-width: 150px;
  cursor: pointer;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image:
          linear-gradient(45deg, transparent 50%, $color-medium 50%),
          linear-gradient(135deg, $color-medium 50%, transparent 50%);
  background-position:
          calc(100% - 20px) 18px,
          calc(100% - 15px) 18px;
  background-size:
          5px 5px,
          5px 5px;
  background-repeat: no-repeat;

  &.invalid {
    border: $border-invalid;
  }

  .selectionText {
    @include text-ellipsis();
    margin-right: 10px;
    width: 100%;
  }

  &.isOpen {
    background-color: $color-action;
    color: $color-white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.isDisabled {
    background-color: $color-light;
    color: $color-white;
    cursor: not-allowed;
  }
}

.optionsPanel {
  position: absolute;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: $border-standard;
  max-height: 250px;
  overflow: auto;
  z-index: 100;

  &.isOpen {
    visibility: visible;
  }
}

.optionItem {
  @include text-ellipsis();
  padding-left: 15px;
  background-color: $color-white;
  cursor: pointer;
  min-height: $line-height;
  line-height: $line-height;
  max-width: 100%;

  &:hover {
    background-color: $color-light;
  }
}

select > option.hiddenOption {
  display: none;
}
