@import '../../../styles/variables';
@import '../../../styles/global';

.page {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
}

.content {
  padding: 30px;
}

.panel {
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 20px;
  background-color: white;
  min-width: 800px;
}

.formInfo {
  line-height: 24px;
  width: 100%;
  background-color: $color-light;
  padding: 10px;
  border: 1px solid $color-medium;
  border-radius: $border-radius-standard;

  .csvField {
    display: flex;

    .key {
      width: 150px;
      font-weight: bold;
    }

    .type {

    }
  }
}

.formField {
  margin-bottom: 20px;
  width: 400px;
}

.input, .labelContainer, .dropdownInput {
  width: calc(100%);
}

.input {
  background-color: $color-light;
  height: $line-height;
  line-height: $line-height;
  display: flex;
}

.input:focus {
  border-radius: $border-radius-standard;
  border: 1px solid $color-action;
}

.buttons {
  margin-top: 30px;
}

.floatRight {
  float: right;
}

.loadingIcon {
  fill: $color-white;
  width: 20px;
  height: 20px;
}

.errorField {
  line-height: 24px;
  padding: 10px;
  background-color: $color-invalid;
  color: white;
  width: 100%;
  border-radius: $border-radius-standard;
}

.download {
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  color: $color-action;

  &:hover {
    color: lighten($color-action, 10%);
  }
}
