@import "../../../styles/variables";
@import "../../../styles/mixins";

.languages {
  display: flex;
  gap: 5px;
  align-items: center;

  .language {
    cursor: pointer;
  }
}

.footer {
  color: lighten($color-action, 10%);
  justify-content: space-between;

  .language:hover {
    color: $color-white;
  }
}

.body {
  color: $color-action;

  .language:hover {
    color: lighten($color-action, 10%);
  }
}
