@import '../../../styles/variables';
@import '../../../styles/global';

.configurationPanel {
  line-height: 24px;
  width: 100%;
  border: 1px solid $color-medium;
  border-radius: $border-radius-standard;

  .panelTitle {
    font-size: $font-size-bigger;
    font-family: TTNormsBold;
    padding: 10px 20px;
    border-bottom: 1px solid $color-medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.buttons {
  padding: 0;

  .button {
    min-width: 100px;
  }
}

.invalid {
  display: inline-flex;
  padding-left: 10px;
  vertical-align: middle;
  text-align: center;
  height: $line-height;

  img {
    width: 20px;
    fill: $color-invalid;
  }
}

.field {
  display: flex;
  padding: 5px 20px;
  line-height: 40px;

  .label {
    min-width: 250px;
    display: flex;
  }

  &:nth-child(even) {
    background-color: $color-light;
  }
}

.inlineTable {
  display: flex;
  flex-direction: row;
  justify-items: baseline;
}

.infoIconBox {
  display: flex;
  align-items: center;
}

.infoIcon {
  display: inline-block;
  width: 17px;
  height: 17px;
  fill: $color-action;
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
}
