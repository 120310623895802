@import '../../../styles/variables';
@import '../../../styles/global';

.configurationField {
  display: flex;
  padding: 5px 20px;
  line-height: 40px;

  &:nth-child(even) {
    background-color: $color-light;
  }

  .label {
    width: 250px;
    display: flex;
  }
}

.infoIconBox {
  display: flex;
  align-items: center;
}

.infoIcon {
  display: inline-block;
  width: 17px;
  height: 17px;
  fill: $color-action;
  margin-left: 5px;

  &:hover {
    cursor: pointer;
  }
}
