@import '../../../styles/variables';
@import '../../../styles/global';

.page {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
}

.content {
  padding: 30px;
}

.panel {
  border-radius: $border-radius-standard;
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
  padding: 0px 20px;
  background-color: white;
  min-width: 800px;
}

.formField {
  margin-bottom: 20px;
  width: 400px;
}

.input, .labelContainer, .dropdownInput {
  width: calc(100% - 35px);
}

.dropdownInput {
  margin-top: 5px;
}

.input {
  height: $line-height;
  background-color: $color-light;
}

.input:focus {
  border-radius: $border-radius-standard;
  border: 1px solid $color-action;
}

.inputInvalid, .inputInvalid:focus {
  border: 1px solid $color-invalid !important;
}

.buttons {
  margin-top: 20px;
}

.floatRight {
  float: right;
}

.invalid {
  display: inline-flex;
  padding-left: 10px;
  vertical-align: middle;
  text-align: center;
  height: $line-height;

  img {
    width: 20px;
    fill: $color-invalid;
  }
}

.loadingIcon {
  fill: $color-white;
  width: 20px;
  height: 20px;
}
