@import '../../../styles/variables';
@import '../../../styles/global';

.page {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
}

.content {
  padding: 50px;
  max-width: 1400px;
  display: inline-flex;
  flex-direction: column;
  gap: 30px;
}

.panel {
  position: relative;
  width: 100%;
  height: 690px;
  background-color: $color-light;
  padding: 20px;
  border: 1px solid $color-medium;
  border-radius: $border-radius-standard;
}

.pdfContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 620px;
  width: 1100px;
  overflow: auto;

  .document {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.pdfControls {
  @include no-select();
  height: 30px;
  width: 1100px;

  .downloadControls {
    position: absolute;
    left: 30px;
    z-index: 2;
  }

  .pageControls {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .zoomControls {
    position: absolute;
    right: 30px;
    display: flex;
    z-index: 2;
  }
}

.pdfPageControls {
  @include no-select();
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pdfZoomControls {
  @include no-select();
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
}

.pdfControl {
  width: 20px;
  height: 20px;
  margin: 5px;

  &:hover {
    cursor: pointer;
  }
}

.buttons {
  padding: 0;
}

.filesContainer {
  display: flex;

  .language {
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .languageGroup {
    margin-right: 30px;
    display: flex;
    align-items: flex-start;

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 280px;

      .button {
        width: 100%;

        &.active {
          background-color: $color-stadim-red;
        }
      }
    }
  }
}
