@import '../../../styles/variables';
@import '../../../styles/mixins';

@mixin menuItemSelected() {
  border-top: 4px solid transparent;
  border-bottom: 4px solid $color-stadim-red;
}

.container {
  box-sizing: border-box;
  @include no-select();
  display: flex;
  align-items: center;
  width: 100%;
  height: $app-header-height;
  padding: 0 20px;
  background-color: $color-dark;
  color: $color-white;
  justify-content: space-between;

  .leftPanel {
    display: flex;
    height: 100%;
    align-items: center;
    //gap: 10px;

    .logo {
      height: 25px;
      cursor: pointer;
    }

    .appName {
      display: flex;
      align-items: center;
      background-color: $color-stadim_red;
      height: 50px;
      padding: 0 20px;
      font-weight: bolder;
      margin-left: 10px;
    }

    .menuItem {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 30px;
      font-size: $font-size-big;

      &:hover {
        cursor: pointer;
        @include menuItemSelected;
      }
    }
  }

  .item {
    padding: 0 15px
  }

  .action {

    &:hover {
      cursor: pointer;
    }
  }

  .rightPanel {
    display: flex;
    gap: 10px;
  }
}

.selected {
  @include menuItemSelected;
}

.menuItemContainer {
  display: flex;
}
