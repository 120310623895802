@import '../../../styles/variables';
@import '../../../styles/global';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: $font-size-huge;
  font-weight: bold;
}

.buttonsContainer {
  display: flex;
  margin-top: auto;
  grid-gap: 10px;
}

.text {
  margin-top: 20px;
}

