//==================================//
//============= COLORS =============//
//==================================//
$color-black: #000000;
$color-white: #ffffff;

$color-stadim-light: #EDE9DE;
$color-stadim-green_light: #BAD3D1;
$color-stadim-green: #6C9196;
$color-stadim-green-dark: #46788B;
$color-stadim-red: #C80000;
$color-stadim-blue: #6AA6C7;
$color-stadim-disabled: #ddd;

$color-dark: #14475A;
$color-light: #f5f5f5;
$color-medium: #e1e0e0;
$color-action: $color-stadim-green-dark;
$color-invalid: $color-stadim_red;
$color-positive: #57cc3f;

//===============================//
//======= SHARED SIZES ==========//
//===============================//
$line-height: 40px;
$mobile-threshold: 700px;
$app-header-height: 50px;

//=====================================//
//========== BORDER RADIUS ============//
//=====================================//

$border-radius-standard: 5px;

//==================================//
//========== FONT SIZES ============//
//==================================//

$font-size-small: 11px;
$font-size-normal: 14px;
$font-size-big: 16px;
$font-size-bigger: 18px;
$font-size-huge: 21px;

//==========================================//
//========== BORDERS ============//
//==========================================//
$border-white: 1px solid $color-white;
$border-standard: 1px solid $color-medium;
$border-focus: 1px solid $color-light;
$border-invalid: 1px solid $color-invalid;

//=========================//
//======= SHADOWS =========//
//=========================//
$heavy-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
$light-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
