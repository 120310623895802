@import "../../../styles/variables";
@import "../../../styles/mixins";

.userButton {
  min-height: $line-height;
  height: 100%;
  color: $color-white;
  padding: 0 0 0 20px;
  border: none;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .userAccount {
    min-height: $line-height;
    display: flex;
    align-items: center;
  }

  .userBank {
    font-size: $font-size-small;
  }

  &:hover {
    cursor: pointer;
  }
}

.dropdown {
  z-index: 999;
  position: absolute;
  right: 0;
  top: 50px;
  width: 200px;
  border: $border-standard;
  border-right: none;
  border-top: none;
  border-bottom-left-radius: $border-radius-standard;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  -webkit-box-shadow: 0px 29px 35px -23px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 0px 29px 35px -23px rgba(0, 0, 0, 0.31);
  box-shadow: 0px 29px 35px -23px rgba(0, 0, 0, 0.31);

  opacity: 1;
  transition: opacity 0.1s linear;

  .imgWrapper {
    width: 30%;
    margin: 0 10px;
    padding: 0;
  }

  .dropdownLink {
    @include no-select();
    border-bottom: $border-standard;
    padding: 10px;
    padding-left: 20px;
    height: $line-height;
    color: $color-black;
    cursor: pointer;

    &:hover {
      background-color: $color-light;
    }
  }

  .dropdownLink:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: $border-radius-standard;
  }
}

.hidden {
  display: none;
}

.top {
  top: 55px;
}
